import { useEffect, useState } from "react"
import { GalleryMessager } from "../api/apiToGallery";

export default function HomePage(){
    const [galleryInfo,setGalleryInfo] = useState({});
    async function updateGalleryInfo(){
        // 获取并显示幻想动物画廊概况
        console.log("done");
        GalleryMessager.getWebsiteInfo().then(res=>{setGalleryInfo(res);});
    };
    useEffect(
        ()=>{
            updateGalleryInfo();
        },[]
    );
    return (
        <>
            <div className="container-fluid">
                <div className="row headBox">
                    <div className="col-sm-6">
                        <img
                            src="/assets/sign/pinkcandy.png"
                            className="img-fluid rounded-top"
                            alt="gallery"
                        />
                    </div>
                    <div className="col-sm-6">
                        <h1 className="headTitle">PINKCANDY</h1>
                        <p className="infoBox">
                            粉糖粒子是粉糖粒子周周创建的兽控同好工作组，
                            粉糖粒子于2023.10.1起名。<br/>
                            联系我们：<br/>
                            粉糖粒子官网 pinkcandy.top<br/>
                            周周的工作邮箱 pinkcandyzhou@qq.com<br/>
                            粉糖毛绒花园 QQ751711878<br/>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <img
                            src="/assets/sign/pinkcandy_gallery.png"
                            className="img-fluid rounded-top"
                            alt="gallery"
                        />
                    </div>
                    <div className="col-sm-8">
                        <h1>幻想动物画廊</h1>
                        <a href="http://gallery.pinkcandy.top">
                            <h2>gallery.pinkcandy.top</h2>
                        </a>
                        <div className="bigText">
                            <span className="badge text-bg-secondary">{galleryInfo.accountNum}</span>
                            小兽加入粉糖<br/>
                            <span className="badge text-bg-secondary">{galleryInfo.galleryNum}</span>
                            画廊<br/>
                            <span className="badge text-bg-secondary">{galleryInfo.gardenNum}</span>
                            盆栽<br/>
                            <span className="badge text-bg-secondary">{galleryInfo.tagsNum}</span>
                            标签<br/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <img
                            src="/assets/sign/pinkcandy_game.png"
                            className="img-fluid rounded-top"
                            alt="gallery"
                        />
                    </div>
                    <div className="col-sm-8">
                        <h1>粉糖粒子游戏</h1>
                        <a href="http://game.pinkcandy.top">
                            <h2>game.pinkcandy.top</h2>
                        </a>
                        <a href="http://pinkcandyzhou.itch.io">
                            <h2>pinkcandyzhou.itch.io</h2>
                        </a>
                        <p>存放粉糖粒子制作的游戏</p>
                    </div>
                </div>
            </div>
        </>
    )
}